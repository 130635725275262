.container {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  font-family: Arial, Helvetica, sans-serif;
  background: url("../../../public/bg_2.gif") fixed;
  overflow: scroll;
}
